/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className?: string
  bgImage?: string
  title?: string
  subTitle?: string
}
const TilesWidget1 = ({
  className,
  bgImage = toAbsoluteUrl('/media/misc/PC12-2.jpg'),
  title = '',
  subTitle = '',
}: Props) => {
  // Use free api to find an image with the keyword you want
  // const keyword = 'Airport runway'
  // const url = `https://source.unsplash.com/featured/?${keyword}`

  return (
    <div
      className={clsx('card h-150px bgi-no-repeat bgi-size-cover', className)}
      style={{
        // add a 50% overlay to make the text readable
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),url('${bgImage}')`,
      }}
    >
      <div className='card-body p-6'>
        <p className='text-white text-hover-primary fw-bold fs-7 mb-1'>{title}</p>
        <a href={subTitle ? '/airport/' + subTitle : '#'}>
          <p className='text-white text-hover-primary fw-bold fs-1'>{subTitle}</p>
        </a>
      </div>
    </div>
  )
}

export {TilesWidget1}
