/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  data: MemberData[]
  loading: boolean
}

type MemberData = {
  public_id: string
  total_flight_time: number
  total_flights: number
  username: string
  avatar: string
}

const MembersTable: React.FC<Props> = ({className, data, loading}) => {
  useEffect(() => {}, [loading])
  if (loading) {
    return (
      <div className={`card mb-5 card-rounded-bottom`}>
        {/* begin::Body */}
        <div className='card-body d-flex flex-column p-0'>
          <Skeleton
            count={1}
            height={555}
            style={{borderRadius: '0.75rem', opacity: '0.2'}}
            highlightColor={getCSSVariableValue('--bs-gray-100')}
            baseColor={getCSSVariableValue('--bs-gray-200')}
          />
        </div>
        {/* end::Body */}
      </div>
    )
  }

  if (!data.length) {
    return <>No data found</>
  }

  // get the first 5 items
  const dataSlice = data.slice(0, 5)

  // Highest flight count
  const highestFlightCount = data.reduce((prev, current) =>
    prev.total_flights > current.total_flights ? prev : current
  )

  const getFlightCountPercentage = (flightCount: number) => {
    return (flightCount / highestFlightCount.total_flights) * 100
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top Pilots</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>By Flight Time</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='/roster'
            className='btn btn-sm btn-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Full Roster
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>#</th>
                <th className='min-w-150px'>Username</th>
                <th className='min-w-140px'>Total Time</th>
                <th className='min-w-120px'>Flights</th>
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {dataSlice.map((member, i) => (
                <tr key={'roster-' + member.public_id}>
                  <td>{i + 1}</td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img
                          src={
                            member.avatar ||
                            'https://rotate-group.b-cdn.net/user-images/default.jpg'
                          }
                          alt=''
                        />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a
                          href={'/profile/' + member.username}
                          className='text-dark fw-bold text-hover-primary fs-6'
                        >
                          {member.username}
                        </a>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          
                        </span> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {(member.total_flight_time || 0).toFixed(1)}
                    </a>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>Hours</span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>
                          {member.total_flights}
                        </span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: getFlightCountPercentage(member.total_flights) + '%'}}
                        ></div>
                      </div>
                    </div>
                  </td>
                  {/* <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen019.svg'
                          className='svg-icon-3'
                        />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {MembersTable}
