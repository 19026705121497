/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useQuery} from 'react-query'
import {getUserStats, store} from '../../../../middleware'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
  } = useQuery('userData', getUserStats, {
    staleTime: Infinity,
    // retry: true,
    // retryDelay: 25,
  })

  return (
    <>
      <div
        style={{
          backgroundColor: '#707070',
          width: '100%',
          padding: '0.5rem 0',
          textAlign: 'center',
          fontSize: '1rem',
        }}
      >
        Download our standalone Flight Tracker application for MSFS, XPLANE, P3D, and FSX!{' '}
        <a
          href='https://github.com/Rotate-Group/acars-release/releases/download/v1.1.4/Titan-Tracker-Setup-1.1.4.exe'
          target='_blank'
          style={{
            color: '#fff',
            textDecoration: 'underline',
            fontWeight: 700,
          }}
        >
          Virtual Tradewind Tracker (ACARS)
        </a>
      </div>
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        data-kt-sticky='true'
        data-kt-sticky-name='header'
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
        {...attributes.headerMenu}
      >
        <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
          {/* begin::Aside mobile toggle */}
          {aside.display && (
            <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
              <div
                className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
                id='kt_aside_mobile_toggle'
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className='svg-icon-2x mt-1'
                />
              </div>
            </div>
          )}
          {/* end::Aside mobile toggle */}

          <div
            className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
            title='Show aside menu'
          >
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
            </div>
          </div>

          <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
            <Link
              to='/'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo.png')}
              className='logo-default h-25px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-alt.png')}
              // src={toAbsoluteUrl('/media/logos/logo.png')}
              className='logo-sticky h-25px'
            /> */}
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo.svg')}
                // src={toAbsoluteUrl('/media/logos/logo.png')}
                className='logo-sticky h-25px'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo.svg')}
                className='logo-default h-25px'
              />
              <span
                style={{
                  color: '#fff',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  marginLeft: '1rem',
                  textTransform: 'uppercase',
                  fontFamily: 'Montserrat, sans-serif',
                  letterSpacing: '0.1rem',
                }}
              >
                Virtual Tradewind
              </span>
            </Link>
          </div>

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <Header />
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <DefaultTitle />
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar data={userData} />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
    </>
  )
}
