export const CARRIER_ID = 'b508eea8-4d59-4ead-b3a0-b7b70e8efe80'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'LGS'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/yancarlos4500/clnorn0yn008v01qugoglakdj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFuY2FybG9zNDUwMCIsImEiOiJja2ZrbnQzdmExMDhnMzJwbTdlejNhdnJuIn0.aoHpGyZLaQRcp8SPYowuOQ'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
      'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const styleMapLine = '#0366b4'
export const styleDarkBg = '#181a1b' //#181a1b // _variable.scss $page-bg-dark:
export const styleDarkGbLighter = '#202224'

export const typeGroupings = []
